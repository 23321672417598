define('hdmz-adwords/router', ['exports', 'ember', 'hdmz-adwords/config/environment'], function (exports, _ember, _hdmzAdwordsConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _hdmzAdwordsConfigEnvironment['default'].locationType,
    rootURL: _hdmzAdwordsConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.authenticatedRoute('index', { path: '/' });
    this.authenticatedRoute('new');
    this.route('preview', { path: 'preview/:upload_id' });
    this.route('login');
    this.route('settings');
  });

  exports['default'] = Router;
});