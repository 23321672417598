define('hdmz-adwords/controllers/new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        firebaseApp: _ember['default'].inject.service(),
        mdl: _ember['default'].inject.service(),
        error: '',
        actions: {
            saveUpload: function saveUpload() {
                var _this = this;

                var csvFile = document.getElementById('csv').files[0];
                var storageRef = this.get('firebaseApp').storage().ref().child('uploads');

                var newUpload = this.store.createRecord('upload', {
                    title: this.get('title'),
                    client: this.get('client'),
                    user: this.get('session').get('currentUser').email,
                    timestamp: Date().toLocaleString(),
                    priority: 0 - Date.now(),
                    fileName: csvFile.name,
                    adwords: false
                });

                newUpload.save().then(function (snap) {
                    storageRef.child(snap.get('id') + '/' + csvFile.name).put(csvFile).then(function () {
                        _this.set('client', '');
                        _this.set('title', '');
                        _this.send('resetFileInput');
                        _this.transitionToRoute('preview', snap.get('id'));
                    })['catch'](function () {
                        _this.store.find('upload', snap.id).then(function (upload) {
                            upload.destroyRecord();
                        });
                        _this.set('error', 'Failed to upload file.');
                    });
                });
            },
            resetFileInput: function resetFileInput() {
                var csvFile = document.getElementById('csv');
                csvFile.value = '';
            }
        }
    });
});
/* global console */