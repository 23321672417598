define('hdmz-adwords/models/upload', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _emberDataModel, _emberDataAttr) {
    exports['default'] = _emberDataModel['default'].extend({
        title: (0, _emberDataAttr['default'])('string'),
        file: (0, _emberDataAttr['default'])('string'),
        user: (0, _emberDataAttr['default'])('string'),
        client: (0, _emberDataAttr['default'])('string'),
        fileName: (0, _emberDataAttr['default'])('string'),
        timestamp: (0, _emberDataAttr['default'])('string'),
        priority: (0, _emberDataAttr['default'])(),
        adwords: (0, _emberDataAttr['default'])()
    });
});