define('hdmz-adwords/routes/login', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            if (this.get('session.isAuthenticated')) {
                this.transitionTo('i');
            }
        },
        actions: {
            signIn: function signIn() {
                var _this = this;

                this.get('session').open('firebase', { provider: 'google' }).then(function () {
                    _this.transitionTo('index');
                });
            }
        }
    });
});