define('hdmz-adwords/components/login-page', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        mdl: _ember['default'].inject.service(),
        didRender: function didRender() {
            this.get('mdl').upgradeDom();
        },
        actions: {
            signIn: function signIn() {
                this.sendAction('signIn');
            }
        }
    });
});