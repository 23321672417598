define('hdmz-adwords/routes/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      return this.get('session').fetch()['catch'](function () {});
    },
    setupController: function setupController(controller) {
      controller.set('title', 'HDMZ Adwords');
    },
    actions: {
      accessDenied: function accessDenied() {
        this.transitionTo('login');
      },
      signOut: function signOut() {
        var _this = this;

        this.get('session').close().then(function () {
          _this.transitionTo('login');
        });
      }
    }
  });
});