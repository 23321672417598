define('hdmz-adwords/routes/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        mdl: _ember['default'].inject.service(),
        model: function model() {
            return this.get('store').query('upload', { orderBy: 'priority' });
        },
        actions: {
            didTransition: function didTransition() {
                var _this = this;

                setTimeout(function () {
                    _this.get('mdl').upgradeDom();
                }, 1000);
            }
        }
    });
});