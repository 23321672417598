define('hdmz-adwords/components/nav-bar', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'header',
        classNames: ['mdl-layout__header'],
        mdl: _ember['default'].inject.service(),
        didRender: function didRender() {
            this.get('mdl').upgradeDom();
        },
        actions: {
            signOut: function signOut() {
                this.sendAction('signOut');
            }
        }
    });
});