define('hdmz-adwords/components/app-container', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'div',
        classNames: ['mdl-layout', 'mdl-js-layout', 'mdl-layout--fixed-header'],
        actions: {
            signOut: function signOut() {
                this.sendAction('signOut');
            }
        }
    });
});