define('hdmz-adwords/routes/new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        mdl: _ember['default'].inject.service(),
        actions: {
            didTransition: function didTransition() {
                var _this = this;

                setTimeout(function () {
                    _this.get('mdl').upgradeDom();
                }, 1000);
            }
        },
        setupController: function setupController(controller) {
            controller.set('session', this.get('session'));
        }
    });
});