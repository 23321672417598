define('hdmz-adwords/routes/preview', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        mdl: _ember['default'].inject.service(),
        model: function model(params) {
            return this.get('store').findRecord('upload', params.upload_id);
        },
        actions: {
            didTransition: function didTransition() {
                var _this = this;

                setTimeout(function () {
                    _this.get('mdl').upgradeDom();
                }, 200);
            }
        }
    });
});