define('hdmz-adwords/controllers/preview', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        firebaseApp: _ember['default'].inject.service(),
        session: _ember['default'].inject.service(),
        store: _ember['default'].inject.service(),
        actions: {
            saveUpload: function saveUpload(upload) {
                var _this = this;

                var csvFile = document.getElementById('csv').files[0];
                var storageRef = this.get('firebaseApp').storage().ref().child('uploads');

                storageRef.child(upload.get('id') + '/' + upload.get('fileName'))['delete']()['catch'](function () {
                    console.log('File Already Deleted');
                });

                storageRef.child(upload.get('id') + '/' + csvFile.name).put(csvFile).then(function (snapshot) {
                    _this.get('store').findRecord('upload', _this.get('model').get('id')).then(function (upload) {
                        upload.set('fileName', snapshot.metadata.name);
                        upload.set('adwords', false);
                        upload.save();
                    });
                    _this.send('resetFileInput');
                })['catch'](function () {
                    _this.set('error', 'Failed to upload new file');
                });
            },
            downloadFile: function downloadFile() {
                var id = this.get('model').get('id');
                var fileName = this.get('model').get('fileName');
                var storageRef = this.get('firebaseApp').storage().ref().child('uploads');
                storageRef.child(id).child(fileName).getDownloadURL().then(function (url) {
                    var iframe = document.getElementById('download-file');
                    iframe.src = url;
                });
            },
            resetFileInput: function resetFileInput() {
                var csvFile = document.getElementById('csv');
                csvFile.value = '';
            }
        }
    });
});