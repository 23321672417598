define('hdmz-adwords/routes/settings', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            var _this = this;

            return this.get('store').findRecord('setting', 1)['catch'](function () {
                _this.get('store').createRecord('setting', {
                    id: 1,
                    defaultCsv: 'No CSV Uploaded'
                }).save();
            });
        },
        model: function model() {
            return this.get('store').findRecord('setting', 1);
        }
    });
});