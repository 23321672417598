define('hdmz-adwords/controllers/settings', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        firebaseApp: _ember['default'].inject.service(),
        actions: {
            saveDefaultCsv: function saveDefaultCsv() {
                var _this = this;

                var currentCsv = this.get('model').get('defaultCsv');

                var csvFile = document.getElementById('csv').files[0];
                var storageRef = this.get('firebaseApp').storage().ref().child('settings');

                storageRef.child('1/' + currentCsv)['delete']()['catch'](function () {});
                var uploadFile = storageRef.child('1/' + csvFile.name).put(csvFile);

                uploadFile.then(function (snapshot) {
                    _this.get('store').findRecord('setting', _this.get('model').get('id')).then(function (setting) {
                        setting.set('defaultCsv', snapshot.metadata.name);
                        setting.save();
                    });
                    _this.send('resetFileInput');
                })['catch'](function () {
                    _this.set('error', 'Failed to upload new file');
                });
            },
            downloadDefaultCsv: function downloadDefaultCsv() {
                var id = this.get('model').get('id');
                var fileName = this.get('model').get('defaultCsv');
                var storageRef = this.get('firebaseApp').storage().ref().child('settings');
                storageRef.child(id).child(fileName).getDownloadURL().then(function (url) {
                    var iframe = document.getElementById('download-file');
                    iframe.src = url;
                });
            },
            resetFileInput: function resetFileInput() {
                var csvFile = document.getElementById('csv');
                csvFile.value = '';
            }
        }
    });
});